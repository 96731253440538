import React, { Component } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Static Member of Dropdown Component.
 * @param children - Text, Images etc.
 * @param to - Link href
 * @param isOutsideLink - Option for using normal a tag elements instead of gatsby-link components.
 * @param dataFilter - Optional data-filter attribute value
 */
const DropdownItem = ({ children, to, isOutsideLink, dataFilter, active }) =>
  isOutsideLink ? (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      data-filter={dataFilter}
      className={`dropdown-item ${active && 'active'}`}
    >
      {children}
    </a>
  ) : (
    <Link
      to={to}
      className={`dropdown-item ${active && 'active'}`}
      data-filter={dataFilter}
    >
      {children}
    </Link>
  );

/**
 * Dropdown Component.
 *
 * @param variant - String value which Dropdown styling should be used: 'nav' for nav-specific, 'select' for dropdown select-like styling
 * @param icon - String or Element
 * @param id - String value for id. Used for aria-labelledby prop.
 * @param title - String value for parent text.
 * @param children - These are the menu items that appear after clicking the dropdown.
 * @param filter - Optional string filter for DropdownItem's. Uses DropdownItem data-filter attribute to filter Item's.
 * @param alt - Optional alt tag for icon
 */
class Dropdown extends Component {
  static Item = DropdownItem; // Static Member <Dropdown.Item />

  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  handleClick(event) {
    event.preventDefault();

    if (!this.state.showMenu)
      document.addEventListener("click", this.closeMenu);
    this.setState({ showMenu: true });
  }

  closeMenu() {
    this.setState({ showMenu: false });
    document.removeEventListener("click", this.closeMenu);
  }

  componentDidMount() {
    if (this.props.filter) this.filterItems(this.props.filter);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter && this.props.filter !== prevProps.filter) {
      this.filterItems(this.props.filter);
    }
  }

  filterItems(filter) {
    const dropdownMenu = document.querySelector(
      `[aria-labelledby="${this.props.id}"]`
    );
    const items = Array.from(dropdownMenu.children); // direct children of dropdown menu

    items.forEach(item => (item.style.display = "none")); // reset all items to display none

    const filteredItems = items.filter(
      item => item.getAttribute("data-filter") === filter
    );
    filteredItems.forEach(item => (item.style.display = "block"));
  }

  render() {
    const dropdownMenuClass = classNames({
      "dropdown-menu": true,
      show: this.state.showMenu
    });

    const dropdownClass = classNames({
      dropdown: true,
      "dropdown--nav": this.props.variant === "nav",
      "dropdown--select": this.props.variant === "select",
      show: this.state.showMenu,
      "dropdown-menu--has-icon": this.props.icon
    });

    return (
      <div className={dropdownClass}>
        <button
          aria-haspopup="true"
          aria-expanded={this.state.showMenu}
          id={this.props.id}
          className={`dropdown-toggle ${this.props.active && 'active'}`}
          onClick={event => this.handleClick(event)}
        >
          {this.props.icon && (
            <img
              src={this.props.icon}
              alt={this.props.alt}
              style={{ width: 28 }}
            />
          )}
          {this.props.title ? this.props.title : null}
        </button>
        <div aria-labelledby={this.props.id} className={dropdownMenuClass}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  variant: PropTypes.oneOf(["nav", "select"]),
  id: PropTypes.string.isRequired,
  filter: PropTypes.string
};

Dropdown.defaultProps = {
  filter: ""
};

DropdownItem.propTypes = {
  to: PropTypes.string.isRequired,
  isOutsideLink: PropTypes.bool
};

DropdownItem.defaultProps = {
  isOutsideLink: false
};

export default Dropdown;
