import React, { useState, useEffect } from "react";

/**
 * Reading Progress Component. Calculates current reading progress of user on the page and displays indicator that grows
 * in size.
 * @param targetEl - Element that the scroll progress should be tracked within. Defaults to document.body.
 * @param rootEl - Root Element that tracks the scrolling. Defaults to window.
 */
const ReadingProgress = ({ targetEl, rootEl }) => {
  const [progress, setProgress] = useState(0);
  let max = 0;

  useEffect(() => {
    if (targetEl !== document.body) measure(document.querySelector(targetEl));
    else measure(targetEl);
    rootEl.addEventListener("scroll", handleScroll);

    return () => rootEl.removeEventListener("scroll", handleScroll);
  });

  // Calculates the target Elements top Scroll Position
  const measure = target => {
    const targetHeight = target.getBoundingClientRect().height;
    const viewportH = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    max = targetHeight - viewportH + target.offsetTop;
  };

  const handleScroll = () => {
    const value = rootEl.scrollY;
    setProgress(Math.round((value / max) * 100));
  };

  return (
    <div className="header-progress">
      <span style={{ width: `${progress}%` }} />
    </div>
  );
};

ReadingProgress.defaultProps = {
  targetEl: typeof document !== "undefined" ? document.body : null,
  rootEl: typeof window !== "undefined" ? window : null
};

export default ReadingProgress;
