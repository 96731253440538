import React, { useState, useRef } from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "../components/Dropdown";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { findFlagBySlug } from "../helper/graphql";
import ReadingProgress from "../components/ReadingProgress";
import { Link } from "gatsby";
import InputWithIcon from "../components/InputWithIcon";

/**
 * Header + Navbar Component.
 * @param translations - Array with all available translations of the current page.
 * @param homepage - The homepage for the currently used language.
 * @param language -
 * @param menu -
 * @param logo -
 * @param showProgressbar
 * @param progressBarTarget
 */
const Header = ({
  translations,
  homepage,
  language,
  menu,
  logo,
  showProgressbar,
  progressBarTarget,
  searchPlaceholder,
  pagePath,
  location
}) => {
  const refInput = useRef(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  // get all Flag Icons URL's
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      ...allFlags
      defaultLogo: file(
        relativeDirectory: { eq: "logos" }
        name: { eq: "en" }
      ) {
        childImageSharp {
          fixed(height: 35) {
            src
            srcSet
          }
        }
      }
    }
  `);

  const logoImage = logo && logo.node.childImageSharp.fixed;
  const defaultLogo = data.defaultLogo.childImageSharp.fixed;

  const handleSearchInput = event => {
    setSearchText(event.target.value);
  };

  const handleSearchClick = event => {
    event.preventDefault();

    if (!searchOpen) document.addEventListener("click", closeMenu);
    setSearchOpen(true);
  };

  const handleSearchKey = event => {
    event.preventDefault();

    // TODO make sure enter key works on every device (mobile etc.) maybe add second click to search icon for fallback
    if (event.key === "Enter") {
      navigate(`${language.root_path}search/?keywords=${searchText}`);
    }
  };

  const closeMenu = event => {
    if (refInput.current && !refInput.current.contains(event.target)) {
      setSearchOpen(false);
      document.removeEventListener("click", closeMenu);
    }
  };

  return (
    <header className="page-header">
      <Navbar expand="lg">
        {searchOpen ? (
          <input
            ref={refInput}
            placeholder={searchPlaceholder}
            type="text"
            autoFocus
            className="navbar-search"
            onChange={event => handleSearchInput(event)}
            onKeyUp={event => handleSearchKey(event)}
            value={searchText}
          />
        ) : (
          <>
            <Navbar.Brand>
              <Link to={homepage}>
                {logoImage ? (
                  <img
                    src={logoImage.src}
                    srcSet={logoImage.srcSet}
                    alt="Amazon Associates Logo"
                  />
                ) : (
                  <img
                    src={defaultLogo.src}
                    srcSet={defaultLogo.srcSet}
                    alt="Amazon Associates Logo"
                  />
                )}
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <span className="icon icon-burger" />
            </Navbar.Toggle>
          </>
        )}

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto align-items-stretch">
            {searchOpen
              ? null
              : menu.map(item => {
                  if (item.child_items) {
                    // is dropdown
                    return (
                      <Dropdown
                        key={item.title}
                        title={item.title}
                        id="nav-dropdown"
                        variant="nav"
                        active={location?.pathname ? item.child_items.some( childItem => location.pathname.includes(childItem.url)) : false}
                      >
                        {item.child_items.map(childItem => (
                          <Dropdown.Item
                            key={childItem.title}
                            to={childItem.url}
                            active={location?.pathname ? location.pathname.includes(childItem.url) : false}
                          >
                            {childItem.title}
                          </Dropdown.Item>
                        ))}
                      </Dropdown>
                    );
                  } else {
                    return (
                      <Nav.Link key={item.url} href={item.url} active={location?.pathname ? location.pathname.includes(item.url) : false}>
                        <span dangerouslySetInnerHTML={{ __html: item.title}} />
                      </Nav.Link>
                    );
                  }
                })}
            <Nav.Item className="nav-item--button nav-item--padding-small nav-search order-first order-lg-0">
              <button
                onClick={event => handleSearchClick(event)}
                className="d-none d-lg-block"
              >
                <span className="icon-search size-25" />
              </button>
              <InputWithIcon
                className="d-flex d-lg-none"
                onInput={event => handleSearchInput(event)}
                onSend={() => console.log(searchText)}
                id="search"
              />
            </Nav.Item>
            {translations && translations.length > 0 && (
              <Dropdown
                variant="nav"
                icon={findFlagBySlug(language.locale, data.allFile.edges)}
                alt={language.locale}
                id="flag-switch"
              >
                {translations.map(translation => (
                  <Dropdown.Item key={translation.locale} to={translation.href}>
                    <img
                      src={findFlagBySlug(
                        translation.locale,
                        data.allFile.edges
                      )}
                      alt={translation.locale}
                      style={{ width: 28 }}
                    />
                    <div>
                      <span className="text-capitalize">
                        {translation.locale_native_name}
                      </span>{" "}
                      -{" "}
                      <span className="text-uppercase">
                        {translation.locale_short.split('-')[0]}
                      </span>
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {showProgressbar && <ReadingProgress targetEl={progressBarTarget} />}
    </header>
  );
};

Header.defaultProps = {
  showProgressbar: true,
  searchPlaceholder: "Search"
};

export default Header;
