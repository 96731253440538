import React from "react";
import PropTypes from "prop-types";

/**
 * Input with icon in front of it.
 * @param icon - Icon string from icon-font spec. Use only search instead of icon-search. Defaults to 'search'.
 * @param id - String used for aria-describedby
 * @param placeholder - Input placeholder string value. Defaults to 'Search'.
 * @param className - Additional classNames for wrapper element.
 * @param onSend - Function executing when user send the input.
 * @param onInput - Function executing when user typed into input.
 */
const InputWithIcon = ({
  icon,
  id,
  placeholder,
  className,
  onSend,
  onInput
}) => {
  const handleKeyUp = event => {
    if (event.key === "Enter") {
      onSend();
    }
  };

  return (
    <div className={`input-icons ${className}`}>
      <span className={`icon icon-${icon}`} id={id} />
      <input
        type="text"
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby={id}
        onKeyDown={event => handleKeyUp(event)}
        onChange={event => onInput(event)}
      />
    </div>
  );
};

InputWithIcon.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onSend: PropTypes.func,
  onInput: PropTypes.func
};

InputWithIcon.defaultProps = {
  icon: "search",
  placeholder: "Search",
  className: "",
  onSend: () => null,
  onInput: () => null
};

export default InputWithIcon;
