import React from "react";
import { Helmet } from "react-helmet";

const TrackingCodes = () => {
  return (
    <Helmet>
      <script
        type="text/javascript"
        async
        defer
        src="https://t.amazon-affiliate.eu/matomo.js"
      />
      <meta name="google-site-verification" content="8r12qqYR97x_HJ-Nadxtv-rIC55tPkJrrTR6A57hWKE" />
    </Helmet>
  );
};

export default TrackingCodes;
